import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { RiMenu3Fill } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { FaMinus } from "react-icons/fa";
import { styled } from "styled-components";
import { FaPlus } from "react-icons/fa6";
const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: black;
  z-index: 10000;
  color: white;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 200;
      setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [open1, setopen1] = useState(false);
  const Icon = isOpen ? GrClose : RiMenu3Fill;
  const Icon2 = open1 ? FaMinus : FaPlus;

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <div
        className={isScrolled ? "navbar-d1 slideInDown navi-true" : "navbar-d1"}
      >
        <div className="navbar-d2">
          <div className="logo-div-nav">
            <h1 onClick={() => navigate("/")} className="logo-h1-1">
              {" "}
              Manpreet Nijjar
            </h1>
          </div>
          <div className="nav-links">
            <ul className="nav-ul1">
              <li className="li-navi">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#ea1b29" : "white",
                  })}
                  to="/"
                >
                  Home
                </NavLink>
              </li>

              <div className="dropdown-container">
                <button className="dropdown-button">Programs</button>
                <div className="dropdown-content">
                  <p>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive ? "#ea1b29" : "black",
                      })}
                      to={"/Health"}
                    >
                      HEALTH SUPPORT
                    </NavLink>
                  </p>
                  <p>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive ? "#ea1b29" : "black",
                      })}
                      to={"/Muscel"}
                    >
                      MUSCLE BUILD / FAT LOOS
                    </NavLink>
                  </p>
                  <p>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive ? "#ea1b29" : "black",
                      })}
                      to={"/Competition"}
                    >
                      COMPETITION COACHING
                    </NavLink>
                  </p>
                </div>
              </div>

              <li className="li-navi">
                <NavLink
                  to={"/EBook"}
                  style={({ isActive }) => ({
                    color: isActive ? "#ea1b29" : "white",
                  })}
                >
                  E-Book
                </NavLink>
              </li>
              <li className="li-navi">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#ea1b29" : "white",
                  })}
                  to={"/About"}
                >
                  About
                </NavLink>
              </li>
              <li className="li-navi">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#ea1b29" : "white",
                  })}
                  to={"/Contact"}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="mobile-bar">
            <Icon onClick={() => setIsOpen(!isOpen)} size={25} />
          </div>
        </div>
      </div>

      <StyledOffCanvas isOpen={isOpen}>
        <div className="side-menu">
          <ul className="ul-nav-2">
            <li onClick={() => setIsOpen(false)} className="nav-li2">
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#ea1b29" : "white",
                })}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <div onClick={() => setopen1(!open1)} className="option-open-1">
              <li className="nav-li2">Programs</li>

              <Icon2 size={20} />
            </div>
            {open1 && (
              <ul className="ul-mb-1">
                <li onClick={() => setIsOpen(false)}>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#ea1b29" : "white",
                    })}
                    to={"/Health"}
                  >
                    HEALTH SUPPORT
                  </NavLink>
                </li>
                <li onClick={() => setIsOpen(false)}>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#ea1b29" : "white",
                    })}
                    to={"/Muscel"}
                  >
                    MUSCLE BUILD / FAT LOOS
                  </NavLink>
                </li>
                <li onClick={() => setIsOpen(false)}>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#ea1b29" : "white",
                    })}
                    to={"/Competition"}
                  >
                    COMPETITION COACHING
                  </NavLink>
                </li>
              </ul>
            )}

            <li className="nav-li2" onClick={() => setIsOpen(false)}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#ea1b29" : "white",
                })}
                to={"/EBook"}
              >
                E-Book
              </NavLink>
            </li>

            <li className="nav-li2" onClick={() => setIsOpen(false)}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#ea1b29" : "white",
                })}
                to={"/About"}
              >
                About
              </NavLink>
            </li>

            <li className="nav-li2" onClick={() => setIsOpen(false)}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#ea1b29" : "white",
                })}
                to={"/Contact"}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />
    </>
  );
};

export default Navbar;
